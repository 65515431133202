/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import UserAvatar from "react-user-avatar";
import LogoutIcon from "../../utils/exit.png";
import { withUser } from "../Context/UserContext";
import KeycloakService from "../../utils/KeycloakService";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const UserSeccion = (props) => {
  const { user } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClick} style={{ marginLeft: "auto" }}>
        <UserAvatar name={user.name} size="45" />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={KeycloakService.doLogout}>
          <img
            src={LogoutIcon}
            style={{ height: "50px", width: "50px", alignSelf: "center" }}
          />
        </StyledMenuItem>
        <StyledMenuItem>{user.name}</StyledMenuItem>
      </StyledMenu>
    </React.Fragment>
  );
};

export default withUser(UserSeccion);
