import React from "react";
import { Route, Router, Switch } from "react-router";
import history from "../../utils/history";
import SolicitudInformatica from "../Modules/Solicitud/SolicitudInternoInformatica";
import SolicitudInterno from "../Modules/Solicitud/SolicitudInternoInterno";
import SolicitudInternoDatascan from "../Modules/Solicitud/SolicitudInternoDatascan";
import SolicitudInternoImasd from "../Modules/Solicitud/SolicitudInternoImasd";
import SolicitudCanon from "../Modules/Solicitud/SolicitudInternoCanon";

const ModulesInterno = () => {
  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          {/* <Redirect exact from="/" to="/solicitud" /> */}
          <Route path={`/canon`} exact component={SolicitudCanon} />
          <Route path={`/informatica`} exact component={SolicitudInformatica} />
          <Route path={`/interno`} exact component={SolicitudInterno} />
          <Route path={`/datascan`} exact component={SolicitudInternoDatascan} />
          <Route path={`/devops`} exact component={SolicitudInternoImasd} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default ModulesInterno;
