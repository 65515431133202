/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Bar from "./Bar";
import Modules from "../routes/Modules";
import ModulesInterno from "../routes/ModulesInterno";
import { withUser } from "../Context/UserContext";

const AppLayout = (props) => {
  const { user } = props;

  return (
    <React.Fragment>
      <Bar />
      {user.cliente ? <Modules /> : <ModulesInterno />}
    </React.Fragment>
  );
};

export default withUser(AppLayout);
