import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@material-ui/core";
//import axiosNuevo from "axios";
import axios from "../../../utils/axios";
import KeycloakService from "../../../utils/KeycloakService";
import moment from "moment";
import { withUser } from "../../Context/UserContext";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "70%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  container: { paddingTop: "12px" },
  table: {
    Width: "90%",
  },
  critico: {
    backgroundColor: "#F91616",
    color: "white",
  },
  bajo: {
    backgroundColor: "#01C12B",
    color: "white",
  },
}));

const Servicios = (props) => {
  //const origin = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`;
  const [servicios, setServicios] = useState([]);
  const classes = useStyles();
  const { user } = props;

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(`/portal/servicios`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        })
        .then((res) => {
          setServicios(res.data.result.page);
        });
      /*
      await axiosNuevo
        .get(`${origin}/portal/servicios`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        })
        .then((res) => {
          setServicios(res.data.result.page);
        });
        */
    };

    getData();
  }, [user.cliente]);

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{
          marginTop: "20px",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ width: "95%", height: "90%" }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nro. Informe Servicio</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Equipo</TableCell>
                <TableCell>Observacion</TableCell>
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {servicios.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.numero}
                  </TableCell>
                  <TableCell>
                    {moment(row.fecha).format("YYYY-MM-DD")}
                  </TableCell>
                  {row.equipo ? (
                    <TableCell>
                      {row.equipo.descripcion +
                        " / " +
                        row.equipo.marca +
                        " / " +
                        row.equipo.modelo +
                        " / " +
                        row.equipo.nroSerie}
                    </TableCell>
                  ) : (
                    <TableCell>-</TableCell>
                  )}
                  <TableCell>{row.observacion}</TableCell>
                  <TableCell
                  //className={`${
                  //  row.estado === "Pendiente"
                  //    ? classes.critico
                  //    : classes.bajo
                  //}`}
                  >
                    {row.estado}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Alert
          severity="warning"
          style={{
            marginTop: "20px",
            maxwidth: "100%",
            textAlign: "center",
          }}
        >
          Los Informes de Servicios que se muestran en la planilla corresponden
          solo a los Servicios solicitados por este medio durante el periodo de
          Recepcion y Asignacion de los tecnicos
        </Alert>
      </Grid>
    </React.Fragment>
  );
};

export default withUser(Servicios);
