/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Grid,
  Typography,
  Button,
  Paper,
  InputBase,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";
//import axiosNuevo from "axios";
import axios from "../../../utils/axios";
import moment from "moment";
import logo from "../../../utils/sgs.png";
import KeycloakService from "../../../utils/KeycloakService";

const EquiposInternos = (props) => {
  //const origin = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`;
  const { open, cerrarBuscador, setEquipo, division } = props;
  const [search, setsearch] = useState("");
  const [equipos, setequipos] = useState([]);

  useEffect(() => {
    const getFiltro = async () => {
      if (search !== "") {
        await axios
          .get(`/portal/equiposinterno`, {
            headers: {
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
            params: {
              rsql: `division.id==${division};nroSerie==*${search}*`,
            },
          })
          .then((res) => {
            setequipos(res.data.result.page);
          });
      } else {
        await axios
          .get(`/portal/equiposinterno`, {
            headers: {
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
            params: {
              rsql: `division.id==${division}`,
            },
          })
          .then((res) => {
            setequipos(res.data.result.page);
          });
      }
    };

    getFiltro();
  }, [division, search]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={0}
          style={{
            minHeight: "60px",
            background: "rgb(0, 25, 65)",
          }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={0}
            >
              <Grid item>
                <img src={logo} height={50} width={50} />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    paddingLeft: "5px",
                    fontSize: "24px",
                  }}
                >
                  Buscador de equipos
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button onClick={() => cerrarBuscador(false)}>
              <Typography
                variant="subtitle1"
                style={{ color: "white", paddingRight: "20px" }}
              >
                Cerrar
              </Typography>

              <CancelIcon
                style={{
                  color: "#F91616",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            paddingBottom: "8px",
            backgroundColor: "white",
            background: "rgb(0, 25, 65)",
          }}
        >
          <Grid
            item
            style={{
              alignItems: "center",
              minWidth: "50%",
            }}
          >
            <Paper
              elevation={1}
              style={{
                padding: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: "100%",
              }}
            >
              <DebounceInput
                element={InputBase}
                debounceTimeout={500}
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                placeholder={"Buscar"}
                //disabled={!!disabled}
                style={{
                  marginLeft: 8,
                  flex: 1,
                  minWidth: "80%",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  lineHeight: "1.375em",
                  padding: "4px",
                }}
              />
              <IconButton
                style={{
                  padding: 10,
                }}
                aria-label="Search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Table
            style={{
              border: "none",
              borderSpacing: "0px 15px",
            }}
          >
            <TableHead
              style={{
                height: "30px",
              }}
            >
              <TableCell>Descripcion</TableCell>
              <TableCell>Marca</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell>Nro Serie</TableCell>
              <TableCell>Fecha Instalacion</TableCell>
            </TableHead>
            <TableBody>
              {equipos.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    onDoubleClick={() => {
                      setEquipo(row);
                    }}
                    //style={styleRow}
                  >
                    <TableCell>{row.descripcion}</TableCell>
                    <TableCell>{row.marca}</TableCell>
                    <TableCell>{row.modelo}</TableCell>
                    <TableCell>{row.nroSerie}</TableCell>
                    <TableCell>
                      {row.fecha_instalacion
                        ? moment(row.fecha_instalacion).format("YYYY-MM-DD")
                        : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

export default EquiposInternos;
