import React from "react";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import history from "../../utils/history";
import Solicitud from "../Modules/Solicitud/Solicitud";
import Servicios from "../Modules/Planilla/Servicios";

class Modules extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router history={history}>
          <Switch>
          <Redirect exact from="/" to="/solicitud" />
            <Route path={`/solicitud`} exact component={Solicitud} />
            <Route path={`/servicios`} exact component={Servicios} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default Modules;
