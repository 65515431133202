import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Grid,
  Input,
  InputLabel,
  Button,
  TextField,
  CardHeader,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import BuscadorEquipoInternos from "../Buscadores/EquiposInternos";
import BuscadorCliente from "../Buscadores/Cliente";
import axios from "../../../utils/axios";
import { store } from "react-notifications-component";
import { withUser } from "../../Context/UserContext";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "15%",
    paddingBlockStart: "5%",
    alignContent: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  container: { paddingTop: "12px" },
}));

const Solicitud = (props) => {
  const classes = useStyles();
  const { user } = props;
  const { register, handleSubmit, errors, reset, setValue } = useForm();
  const [openBuscador, setopenBuscador] = useState(false);
  const [openBuscadorCliente, setopenBuscadorCliente] = useState(false);
  const [equipo, setequipo] = useState();
  const [cliente, setcliente] = useState();

  const SendData = async (servicioPoral) => {
    try {
      const resquest = await axios.post(`/portal/serviciosinternos`, {
        ...servicioPoral,
      });

      if (resquest.status === 201) {
        store.addNotification({
          title: "Creado!",
          message: "Numero de IS " + resquest.data.result,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        setcliente();
        setequipo();
        reset();
      } else {
        store.addNotification({
          title: "Error!",
          message: "Error en la creacion de IS",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    } catch (error) {
      store.addNotification({
        title: "Error!",
        message: "Error en la creacion de IS",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const onSubmit = (data) => {
    let clienteActual = {id:1459};
    /* equipo && equipo.clienteActual
      ? (clienteActual = equipo.clienteActual)
      : (clienteActual = null); */
    let sucursal;
    equipo && equipo.sucursalActual
      ? (sucursal = equipo.sucursalActual)
      : (sucursal = null);
    let observacion = `Contacto: ${data.nombreContacto}
Telefono: ${data.telefonoContacto ? data.telefonoContacto : null}
Horario de Atencion: 
Desde: ${data.desde} Hasta: ${data.hasta}
Observacion: ${data.observacion}`;
    let division = { id: 4 };

    const res = {
      servicioSolicitado: data.servicioSolicitad,
      observacion: observacion,
      equipo: equipo ? equipo : null,
      cliente: cliente ? cliente : clienteActual ? clienteActual : null,
      sucursal: sucursal,
      division: division,
    };

    SendData(res);
  };

  const setEquipo = (rowEquipo) => {
    rowEquipo ? setequipo(rowEquipo) : setequipo({});
    rowEquipo && rowEquipo.clienteActual
      ? setCliente(rowEquipo.clienteActual)
      : console.log("no hay cliente");
    setopenBuscador(false);
  };

  const setCliente = (rowCliente) => {
    rowCliente ? setcliente(rowCliente) : setcliente({});
    setopenBuscadorCliente(false);
  };

  useEffect(() => {
    setValue("nombreContacto", user.name);
  }, [setValue, user]);

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{
          marginTop: "20px",
        }}
      >
        <Card style={{ minWidth: "70%" }}>
          <CardHeader
            title="Portal Servicios Soporte DevOps"
            subheader="Todos los servicios
          cargados en esta pantalla van al area de DevOps 👔"
            className={classes.root}
          />
          <CardContent className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <InputLabel>{JSON.stringify(user)}</InputLabel> */}
              {/* <Grid item>
              <InputLabel>{JSON.stringify(user)}</InputLabel>
                <InputLabel>Cliente</InputLabel>
                <Input
                  style={{
                    marginBottom: "20px",
                    width: "70%",
                  }}
                  name="cli"
                  inputRef={register({
                    minLength: 3,
                  })}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  value={
                    cliente
                      ? cliente.numeroDocumento + " / " + cliente.nombre
                      : "-"
                  }
                  disabled={true}
                />
                <Button onClick={() => setopenBuscadorCliente(true)}>
                  Buscar Cliente
                </Button>
                <ErrorMessage
                  errors={errors}
                  name="cli"
                  message="Cliente es Requerido"
                  render={({ message }) => (
                    <Alert
                      style={{
                        marginBottom: "20px",
                        width: "40%",
                      }}
                      severity="error"
                    >
                      {message}
                    </Alert>
                  )}
                />
              </Grid> */}
              <Grid item>
                <InputLabel>Nro. Serie del Equipo</InputLabel>
                <Input
                  style={{
                    marginBottom: "20px",
                    width: "70%",
                  }}
                  name="equipo"
                  inputRef={register}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  value={
                    equipo
                      ? equipo.descripcion +
                        " / " +
                        equipo.marca +
                        " / " +
                        equipo.modelo +
                        " / " +
                        equipo.nroSerie
                      : "-"
                  }
                  disabled={true}
                />
                <Button onClick={() => setopenBuscador(true)}>
                  Buscar Equipo
                </Button>
                <ErrorMessage
                  errors={errors}
                  name="equipo"
                  render={({ message }) => (
                    <Alert
                      style={{
                        marginBottom: "20px",
                        width: "40%",
                      }}
                      severity="error"
                    >
                      {message}
                    </Alert>
                  )}
                />
              </Grid>
              <Grid item>
                <InputLabel>Servicio Solicitado</InputLabel>
                <Input
                  style={{
                    marginBottom: "20px",
                    width: "80%",
                  }}
                  //multiline={true}
                  name="servicioSolicitad"
                  inputRef={register({ required: "Este campo es Requerido" })}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  rows={2}
                />
              </Grid>
              <ErrorMessage
                errors={errors}
                name="servicioSolicitad"
                render={({ message }) => (
                  <Alert
                    style={{
                      marginBottom: "20px",
                      width: "42%",
                    }}
                    severity="error"
                  >
                    {message}
                  </Alert>
                )}
              />
              <Grid item>
                <InputLabel>Nombre Contacto</InputLabel>
                <Input
                  style={{
                    marginBottom: "20px",
                    width: "80%",
                  }}
                  name="nombreContacto"
                  inputRef={register({ required: "Este campo es Requerido" })}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                />
              </Grid>
              <ErrorMessage
                errors={errors}
                name="nombreContacto"
                render={({ message }) => (
                  <Alert
                    style={{
                      marginBottom: "20px",
                      width: "42%",
                    }}
                    severity="error"
                  >
                    {message}
                  </Alert>
                )}
              />
              <Grid item>
                <InputLabel>Telefono Contacto</InputLabel>
                <Input
                  style={{
                    marginBottom: "20px",
                    width: "80%",
                  }}
                  type="number"
                  name="telefonoContacto"
                  // inputRef={register({
                  //   required: "Este campo es Requerido",
                  // })}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                />
              </Grid>
              <ErrorMessage
                errors={errors}
                name="telefonoContacto"
                render={({ message }) => (
                  <Alert
                    style={{
                      marginBottom: "20px",
                      width: "42%",
                    }}
                    severity="error"
                  >
                    {message}
                  </Alert>
                )}
              />
              <Grid item>
                <InputLabel>
                  Horario en el que puede Recibir al tecnico
                </InputLabel>
                <TextField
                  name="desde"
                  label="Desde"
                  inputRef={register({ required: "Este campo es Requerido" })}
                  type="time"
                  defaultValue="08:00"
                  style={{
                    marginBottom: "20px",
                    width: "35%",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
                <TextField
                  name="hasta"
                  label="Hasta"
                  inputRef={register({ required: "Este campo es Requerido" })}
                  type="time"
                  defaultValue="17:30"
                  style={{
                    marginLeft: "10%",
                    marginBottom: "20px",
                    width: "35%",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </Grid>
              <ErrorMessage
                errors={errors}
                name="desde"
                render={({ message }) => (
                  <Alert
                    style={{
                      marginBottom: "20px",
                      width: "42%",
                    }}
                    severity="error"
                  >
                    {message}
                  </Alert>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="hasta 
                "
                render={({ message }) => (
                  <Alert
                    style={{
                      marginBottom: "20px",
                      width: "42%",
                    }}
                    severity="error"
                  >
                    {message}
                  </Alert>
                )}
              />
              <Grid item>
                <InputLabel>Observacion</InputLabel>
                <Input
                  style={{
                    marginBottom: "20px",
                    width: "80%",
                  }}
                  name="observacion"
                  inputRef={register}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  rows={2}
                />
              </Grid>
              <CardActions>
                <Button
                  type="submit"
                  style={{
                    border: 0,
                    borderRadius: 3,
                    color: "white",
                    height: 48,
                    marginLeft: "35%",
                    padding: "0 60px",
                    background: "rgb(0, 25, 65)",
                  }}
                >
                  Enviar
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
        <BuscadorEquipoInternos
          open={openBuscador}
          cerrarBuscador={setopenBuscador}
          setEquipo={setEquipo}
          division={1}
        />
        <BuscadorCliente
          open={openBuscadorCliente}
          cerrarBuscador={setopenBuscadorCliente}
          setEquipo={setCliente}
        />

        {/* <Alert
          severity="warning"
          style={{
            marginTop: "20px",
            maxwidth: "100%",
            textAlign: "center",
          }}
        >
          Recepcion de Servicios para Informatica, todos los servicios cargados
          en esta pantalla van al area de Informatica
        </Alert> */}
      </Grid>
    </React.Fragment>
  );
};

export default withUser(Solicitud);
