/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import clsx from "clsx";
import history from "../../utils/history";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ReceiptIcon from "@material-ui/icons/Receipt";
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import { withUser } from "../Context/UserContext";
import UserSeccion from "./UserSeccion";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 1,
    background: "rgb(0, 45, 95)",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const Bar = (props) => {
  const classes = useStyles();
  const { user } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {user.cliente ? (
        <List>
          <ListItem button onClick={() => history.push(`/servicios`)}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary={"Servicios Solicitados"} />
          </ListItem>
          <ListItem button onClick={() => history.push(`/solicitud`)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Solicitar Servicio"} />
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem button onClick={() => history.push(`/canon`)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Solicitar Servicio Canon"} />
          </ListItem>
          <ListItem button onClick={() => history.push(`/informatica`)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Solicitar Servicio Informatica"} />
          </ListItem>
          <ListItem button onClick={() => history.push(`/datascan`)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Solicitar Servicio Soporte Datascan"} />
          </ListItem>
          <ListItem button onClick={() => history.push(`/devops`)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Solicitar Servicio DevOps"} />
          </ListItem>
          <ListItem button onClick={() => history.push(`/interno`)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Solicitar Servicio Soporte Interno"} />
          </ListItem>
        </List>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
            //className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            onClick={() => history.push(`/`)}
            style={{ paddingTop: "1opx" }}
          >
            Portal de Servicios
          </Typography>
          <UserSeccion />
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </React.Fragment>
  );
};

export default withUser(Bar);
