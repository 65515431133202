import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import AppLayout from "./components/Layout/AppLayout";
import CssBaseLine from "@material-ui/core/CssBaseline";
import UserProvider from "./components/Context/UserProvider";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

const App = () => {
  return (
    <React.Fragment>
      <React.Fragment>
        <ReactNotification />
        <UserProvider>
          <CssBaseLine />
          <Router history={history}>
            <Switch>
              <Route path="/" component={AppLayout} />
            </Switch>
          </Router>
        </UserProvider>
      </React.Fragment>
    </React.Fragment>
  );
};

export default App;
