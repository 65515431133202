import axios from "axios";
//import { refreshToken } from "./authUtils.js";
import history from "./history";
import KeycloakService from "./KeycloakService";

//export const origin = "http://sgs-api-sgs-dev.apps.lab.data.com.py/api";
export const origin = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`;

export const CancelToken = axios.CancelToken;

const instance = axios.create({
  baseURL: origin,
  timeout: 10000,
});

//CORS activos para prueba.
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.common["Access-Control-Allow-Methods"] = "*";
instance.defaults.headers.common["Access-Control-Max-Age"] = "3600";
instance.defaults.headers.common["Access-Control-Allow-Headers"] = "*";

instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${KeycloakService.getToken()}`;
  return config;
});

// Interceptor para llamar a refreshToken cuando el access token vence.
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("ERROR", error);
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      originalRequest._retry = true;
      const refreshResponse = KeycloakService.updateToken();
      //REACT_APP_AUTH_DISABLED_SIGNIFICA_PELIGRO solo debe usarse en modo de desarrollo
      if (
        process.env.REACT_APP_AUTH_DISABLED_SIGNIFICA_PELIGRO === "true" ||
        refreshResponse.status === "OK"
      ) {
        originalRequest.headers["Authorization"] =
          refreshResponse.authorizationHeader;
        return instance(originalRequest);
      } else {
        console.log("entre por error");
        history.push(`/login`);
      }
    }

    if (error.response && error.response.status === 500) {
      console.log("Error use", KeycloakService.getToken());
    }

    return Promise.reject(error);
  }
);

// Interceptor que hace trim() sobre todos los campos de tipo string mandados
// como payload en peticiones POST/PUT.
instance.interceptors.request.use(
  (config) => {
    if (config.method === "post" || config.method === "put") {
      config.data &&
        Object.keys(config.data).forEach((key) => {
          try {
            if (
              typeof config.data[key] === "string" ||
              config.data[key] instanceof String
            ) {
              config.data[key] = config.data[key].trim();
            }
          } catch {
            console.warn(
              `Axios interceptor: No se pudo hacer trim sobre el campo ${key}. Se usará el valor original dentro del payload.`
            );
          }
        });
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
