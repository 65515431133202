/* eslint-disable react/destructuring-assignment */
import React from "react";
import UserContext from "./UserContext";
import jwtDecode from "jwt-decode";
import history from "../../utils/history";

const initialState = {
  realm_access: {
    roles: [],
  },
  resource_access: {
    account: {
      roles: [],
    },
  },
  division: "",
  cliente: "",
  name: "-",
  preferred_username: "",
  given_name: "",
  family_name: "",
};

class UserProvider extends React.PureComponent {
  state = {
    ...initialState,
    ...this.props.default,
  };

  // Actualmente en desuso, había un mismatch entre las descripciones
  // de divisiones almacenadas en la base de datos y las descripciones de
  // divisiones retornadas en el access_token.
  parcharDescripcion = (descripcion) => {
    switch (descripcion) {
      case "Informatica":
        return "Informática";
      case "Digitalizacion":
        return "Digitalización";
      default:
        return descripcion;
    }
  };

  updateUser = async () => {
    const sgsJwt = JSON.parse(localStorage.getItem("sgs-jwt"));
    if (sgsJwt) {
      const decodedAccessToken = jwtDecode(sgsJwt.token);
      const decodedRefreshToken = jwtDecode(sgsJwt.refreshToken);
      const now = new Date().getTime() / 1000;
      if (
        decodedAccessToken.exp - now > 0 ||
        decodedRefreshToken.exp - now > 0
      ) {

        this.setState({
          realm_access: decodedAccessToken.realm_access,
          resource_access: decodedAccessToken.resource_access,
          cliente: decodedAccessToken.cliente,
          name: decodedAccessToken.name,
          preferred_username: decodedAccessToken.preferred_username,
          given_name: decodedAccessToken.given_name,
          family_name: decodedAccessToken.family_name,
        });
        return;
      }
    }
    //REACT_APP_AUTH_DISABLED_SIGNIFICA_PELIGRO solo debe usarse en modo de desarrollo
    if (process.env.REACT_APP_AUTH_DISABLED_SIGNIFICA_PELIGRO !== "true") {
      history.push("/login");
    }
  };

  clearUser = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    this.updateUser();
  }

  render() {
    const { children } = this.props; //eslint-disable-line
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          updateUser: this.updateUser,
          clearUser: this.clearUser,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
