import React from 'react';

const UserContext = React.createContext({
  realm_access: {
    roles: [],
  },
  resource_access: {
    account: {
      roles: [],
    },
  },
  division: '',
  cliente:'',
  name: '-',
  preferred_username: '',
  given_name: '',
  family_name: '',
});

export function withUser(Component) {
  return props => (
    <UserContext.Consumer>
      {context => <Component {...props} user={{ ...context }} />}
    </UserContext.Consumer>
  );
}

export default UserContext;
